<template>
  <!-- <button @click="deselectRows">deselect rows</button> -->
  <div>
    <ag-grid-vue class="ag-theme-balham" style="height: 700px" :gridOptions="this.gridOptions"
      :columnDefs="columnDefs.value" :defaultColDef="defaultColDef" :enterMovesDown="true" :enterMovesDownAfterEdit="true"
      :stopEditingWhenCellsLoseFocus="true" :enableRangeSelection="true" :paginationPageSize="20" :pagination="true"
      rowSelection="multiple" animateRows="true" @cell-clicked="cellWasClicked" @grid-ready="onGridReady">
    </ag-grid-vue>
  </div>
</template>
     
<script>
import { AgGridVue } from "ag-grid-vue3"; // the AG Grid Vue Component
import { reactive, ref } from "vue";

import startsWith from "lodash.startswith";
import sortBy from "lodash.sortby";
import { useGlobalInfo } from "../stores/globalInfo";
import { useRecentStore } from "../stores/recent";
import { useDatasetEditSessions } from "../stores/dsEditSessions";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { useRouter } from "vue-router";
export default {
  components: {
    AgGridVue,
  },
  setup() {
    const router = useRouter()
    console.log(router)
    const recentStore = useRecentStore();
    const globalInfo = useGlobalInfo();
    const dsEditSessions = useDatasetEditSessions();
    const gridApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
    };

    const rowData = reactive({
      value: [
        //      {
        //        displayDataset: "displayDataset value",
        //        label: "label value",
        //        mainNode: "mainNode value",
        //        nodeCount: "nodeCount value",
        //        partCategory: "partCategory value",
        //      },
      ],
    });

    const columnDefs = reactive({
      value: [
        { field: "datasetID", cellRenderer: linkRenderer },
        { field: "label" },
        { field: "mainNode", cellRenderer: linkRenderer },
        { field: "nodeCount" },
        { field: "partCategory" },
      ],
    });
    function linkRenderer(p) {
      // <router-link :to="{ name: 'SSTDataset', params: { dsID: this.itemRef } }">{{ displayId }}</router-link>
      const route = {
        name: "SSTDataset",
        params: { dsID: p.value }
      };
      const link = document.createElement("a");
      link.href = router.resolve(route).href;
      link.innerText = p.value;
      link.addEventListener("click", e => {
        e.preventDefault();
        router.push(
         route
        );
      });
      return link;
      // return '<router-link :to='+{name: "SSTDataset", params: { dsID: params.value }}+'>' + params.value + '</router-link>';
    }

    const defaultColDef = {
      sortable: true,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      editable: false,
      filter: true,
      flex: 1,
      // singleClickEdit: true,
    };

    var gridOptions = {
      columnDefs: columnDefs,
      rowData: rowData.value,
      animateRows: true,
    };

    return {
      onGridReady,
      columnDefs,
      defaultColDef,
      globalInfo,
      recentStore,
      rowData,
      gridOptions,
      initialized: false,
      dsServiceSessions: dsEditSessions.serviceSessions,
      // cellWasClicked: (event) => {
      //   // Example of consuming Grid Event
      //   console.log("cell was clicked", event);
      // },
      deselectRows: () => {
        gridApi.value.deselectAll();
      },
    };
  },
  data() {
    return {
      filterQuery: this.recentStore.recentDatasetFilterQuery,
      filterQueryHasError: false,
      datasets: null,
      actualGraphID: undefined,
      actualGraphURI: undefined,
      imports: undefined,
      importedBy: undefined,
      isExternal: false,
      nodes: null,
      derived: undefined,
      commits: undefined,
      displayDerivedJSON: localStorage.displayDerivedJSON,
      fetchDataCnt: 0,
    };
  },
  props: {
    commitID: { required: false, type: String },
    graphURI: { required: false, type: String },
    dsID: { required: false, type: String },
  },
  created() {
    this.$watch(
      () => [this.commitID, this.dsID, this.graphURI],
      () => this.fetchData(),
      { immediate: true }
    );

  },
  methods: {
    fetchData() {
      this.fetchDataCnt;
      if (this.fetchDataCnt > 1) {
        return;
      }
      this.fetchDataCnt = 1;
      const partQuery = "mainType:Part";
      this.partQuery !== undefined && this.partQuery !== ""
        ? this.partQuery
        : undefined;
      this.axios({
        method: "get",
        url:
          "/dataset" +
          (partQuery !== undefined
            ? "?q=" + encodeURIComponent(partQuery)
            : ""),
        validateStatus: function (status) {
          return (status >= 200 && status < 300) || status === 422;
        },
      })
        .then((response) => {
          if (response.status === 422) {
            this.partQueryHasError = true;
          } else {
            this.datasets = sortBy(response.data.datasets, [
              (o) => (startsWith(o.uri, "urn:uuid:") ? o.id : o.uri),
            ]);
            this.globalInfo.updateLoggedInUser(response.data);
            this.partQueryHasError = false;
          }
          this.fetchInnerData();
        })
        .catch((error) => {
          throw error;
        });
    },
    fetchInnerData() {
      this.datasets.forEach((dataset) => {
        const sessionID = this.dsServiceSessions.get(dataset.dsID);
        this.axios
          .get(
            "/dataset/" +
            dataset.id +
            (sessionID !== undefined && this.commitID === undefined
              ? "/session/" + sessionID
              : this.commitID !== undefined
                ? "/commit/" + this.commitID
                : "") +
            (this.graphURI !== undefined
              ? "/graph/" +
              encodeURIComponent(encodeURIComponent(this.graphURI)) // Double escaping
              : "")
          )
          .then((response) => {
            this.actualGraphID = response.data.graphID;
            this.actualGraphURI = response.data.graphURI;
            this.nodes = sortBy(response.data.nodes, [
              (o) =>
                !startsWith(o.mainType, "(") ? o.mainType : "\xff" + o.mainType,
              (o) => o.fragment,
            ]);
            this.imports = undefined;
            if (response.data.imports !== undefined) {
              this.imports = sortBy(response.data.imports);
            }
            this.importedBy = undefined;
            if (response.data.importedBy !== undefined) {
              this.importedBy = sortBy(response.data.importedBy);
            }
            if (response.data.isExternal !== undefined) {
              this.isExternal = response.data.isExternal;
            }
            if (response.data.derived !== undefined) {
              this.derived = response.data.derived;
              this.rowData.value.push({
                label: this.derived["label"],
                partCategory: this.derived["part.partCategory"],
                mainNode: this.derived["mainNode"],
                nodeCount: this.derived["nodeCount"],
                datasetID: this.derived["graphID"],
              });
              this.gridOptions.api.setRowData(this.rowData.value);
            }
            this.commits = undefined;
            if (response.data.commits !== undefined) {
              this.commits = response.data.commits;
            }
            this.globalInfo.updateLoggedInUser(response.data);
          });
      });
    },
  },
};
</script>
     
<style></style>