<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
    <p class="name truncate">SST Repository</p>
    <part-list></part-list>
  </template>
  
  <script>
  import PartList from "../components/PartList.vue";
  
  export default {
    name: "PartPage",
    components: {
      PartList,
    },
  };
  </script>
  
  <style>
  </style>